<template>
  <div class="changepwd">
    <!-- 头 -->
    <div class="header">
      <div class="imgs">
        <img src="../assets/logo1.png" alt />
      </div>
      <div class="title">
        <span>健康数据管理中心</span>
      </div>
    </div>
    <div class="main">
      <div class="forget">修改密码</div>
      <el-form class="pwdform" :model="form" label-position="top">
        <el-form-item label="请输入新密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.password" placeholder="请输入新密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请重复一边输入的密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.pwd" placeholder="重复输入新密码" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码" :label-width="formLabelWidth">
          <el-input class="yzm" v-model="form.code" autocomplete="off"></el-input>
          <el-button class="yzmbut" @click="obtain">{{content}}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="but">
        <el-button type="primary" @click="determine">确定</el-button>
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        code: "",
        password: ""
      },
      content: "发送验证码",
      totalTime: 60,
      canClick: true, //添加canClick
      verificationcode: {
        phone: ""
      },
      formLabelWidth: "120px"
    };
  },
  methods: {
    // 取消
    cancel() {
      this.$router.replace("/");
    },
    // 获取验证码
    obtain() {
      // console.log(111)
      var that = this;
      that.form.name = that.$route.params.msgKey.name;
      that.form.phone = that.$route.params.msgKey.tel;
      that.verificationcode.phone = that.$route.params.msgKey.tel;
      // 找回密码
      that.$http({
          url: "/too/login/sendPhoneCode",
          params: that.verificationcode,
          method: "POST"
        })
        .then(res => {
          //验证数据是否获取到
          console.log(res);
          if (res.data.code == 200) {
            that.$message({
              message: "验证码已发送",
              type: "success"
            });
            if (!that.canClick) return; //改动的是这两行代码
            that.canClick = false;
            that.content = that.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              that.totalTime--;
              that.content = that.totalTime + "s后重新发送";
              if (that.totalTime < 0) {
                window.clearInterval(clock);
                that.content = "重新发送验证码";
                that.totalTime = 60;
                that.canClick = true; //这里重新开启
              }
            }, 1000);
          } else {
            that.$message.error("验证码发送失败");
          }
        });
    },
    // 确定
    determine() {
      var that = this;
      //  修改密码
      that
        .$http({
          url: "/too/login/updatePassWord",
          params: that.form,
          method: "POST"
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$message({
              message: "密码修改成功",
              type: "success"
            });
            this.$router.replace("/");
          } else {
            this.$message.error("请输入正确的验证码");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="less">
.changepwd {
  .header {
    margin-top: 100px;
    .imgs {
      text-align: center;
      img {
        width: 356px;
        height: 66px;
      }
    }
    .title {
      font-size: 40px;
      text-align: center;
      color: #91aca7;
      margin-top: 40px;
      letter-spacing: 8px;
      span {
        font-family: "ExtraLight";
      }
    }
  }
  .main {
    width: 500px;
    margin: 0 auto;
    .forget {
      font-size: 34px;
      text-align: center;
      color: #a8a8a8;
      margin-top: 40px;
    }
    .el-form {
      margin-top: 40px;
      .el-form-item__label {
        font-size: 18px;
        color: #a8a8a8;
      }
      .yzm {
        width: 200px;
      }
      .yzmbut {
        margin-left: 20px;
      }
    }
    .but {
      text-align: right;
      margin-top: 60px;
      .el-button {
        height: 50px;
        width: 100px;
        background: #d78673;
        font-size: 18px;
        border: none;
        border-radius: 33px;
      }
    }
  }
}
</style>